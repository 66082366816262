import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import FaqAccordion from "../../../components/faq/faq-accordion";
import MortgageCalculator from "../../../components/calculators/mortgage-calculator/calculator";
import HelpSection from "../../../components/home-loans/help-section";
import ReturningBorrowerLink from "../../../components/home-loans/returning-borrower-link";

// Helpers
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import CustomLargeCards from "../../../components/card/custom-large-cards";
import NewUIWafdBankBenefits from "../../../components/wafd-bank-benefits/new-ui-wafd-bank-benefits";
import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";

const HomeImprovementLoans = () => {
  const title = "Home Improvement Loan - Information & Calculator",
    description =
      "Looking to remodel and need a home improvement loan? Check out our home improvement loan calculator and talk to a renovation loan specialist at WaFd Bank.";
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/remodeling/hero-remodeling-02-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/remodeling/hero-remodeling-02-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/home-loans/remodeling/hero-remodeling-02-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/home-loans/remodeling/hero-remodeling-02-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/home-loans/remodeling/hero-remodeling-02-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/remodeling/hero-remodeling-02-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/remodeling/hero-remodeling-02-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
      CardImage1: file(relativePath: { eq: "cards/articles-cards/thumbnail-better-to-remodel-or-move-091923.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage2: file(relativePath: { eq: "cards/articles-cards/thumbnail-remodeling-projects-1-05082023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage3: file(relativePath: { eq: "cards/articles-cards/blog-financing-small-projects-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      largeCard1: file(relativePath: { eq: "cards/personalization/thumbnail-closing-costs-03152023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard2: file(relativePath: { eq: "cards/thumbnail-remodeling-ebook-110723.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const heroChevronData = {
    id: "home-improvement-hero",
    ...getHeroImgVariables(pageData),
    altText: "Couple laughing while painting a wall in their house.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Build on Future Value",
            class: "text-white"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-1",
            text: "See Today's Rates",
            url: "/personal-banking/home-loans/mortgage-rates",
            class: "btn-white",
            containerClass: "mb-3"
          }
        },
        {
          id: 3,
          button: {
            id: "hero-cta-2",
            text: "Use Loan Calculator",
            url: "#mortgage-calculator-section",
            class: "btn-light",
            containerClass: "mb-3",
            icon: {
              position: "right",
              lib: "fas",
              name: "arrow-down",
              class: "float-right mt-1 ml-3"
            }
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Remodeling"
    }
  ];

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/home-improvement-loans"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-remodeling-02-250.jpg"
      }
    ]
  };

  const cardsData = useCardBlogsData([
    "/blog/home-ownership/remodel-or-move",
    "/blog/building-remodeling/home-improvements-that-add-value",
    "/blog/building-remodeling/best-way-finance-home-improvements"
  ]);
  const featuredCardsData = getFeaturedCardBlogData(cardsData);

  const stretchedCardsData = {
    sectionClass: "text-left pt-0",
    rowColsClass: "row-cols-1 row-cols-md-3",
    title: "",
    titleClass: "text-center",
    hasGrowEffect: false,
    hasRiseShadowEffect: true,
    useMobileView: false,
    cards: featuredCardsData.map((card) => ({ ...card, titleClass: "text-green-60" }))
  };

  const largeCardsImgData = {
    largeCard1: pageData.largeCard1,
    altTextCard1: "Young couple moving in new house, holding keys.",
    largeCard2: pageData.largeCard2,
    altTextCard2: "Couple remodeling their house and looking at blueprints."
  };

  const largeCardsData = [
    {
      title: "Save Up to $600 on Closing Costs With a Checking Account From WaFd Bank*",
      disclaimer:
        "*To qualify for the maximum $600 discount, before closing documents are prepared you must (1) have or open the WaFd checking account, (2) register for EZ-Pay from the new checking account, and (3) sign up for direct deposit of at least one form of a monthly recurring source of income.",
      mainButton: {
        url: "/personal-banking/home-loans/closing-cost-checking-account-offer",
        title: "Find Out How"
      }
    },
    {
      title: "Learn From the Experts: Everything You Need to Know About Remodeling",
      subtitle:
        "After over 100 years in the mortgage business, we've learned a think or two about remodeling. Learn more about the big decisions and details you may not have thought about.",
      mainButton: {
        url: "/documents/wafd-bank-remodeling-ebook.pdf",
        title: "Read More",
        externalLink: true,
        target: "blank"
      }
    }
  ];

  const calculatorData = {
    title: "Remodeling Loan",
    loanType: "Remodeling",
    loanTerm: "30 Year Fixed",
    homeValue: 450000,
    interestRate: 0,
    estimatedPayment: 0,
    purchasePrice: 0,
    cashOutAmount: 0,
    loanAmount: 10000,
    disclaimersSectionClass: "py-0"
  };

  const helpSectionData = {
    sectionClass: "bg-light",
    text: "If you've done all your research and still want help with your construction loan, just stop by. We're nearby, and we'll sit down with you and answer any questions you may have."
  };

  const faqAccordionData = {
    id: "home-improvement-loans-faq-accordion",
    seeMoreFaqsLink: "/personal-banking/home-loans/mortgage-faq",
    seeMoreFaqsId: "home-improvement-loans-see-more-faqs-link",
    faqCategoryNames: ["Remodeling"]
  };

  return (
    <SecondaryLanding showMortgageNotification={true} loanType="Remodeling Loans">
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container pb-0">
        <h1>Remodeling</h1>
        <h3>
          Don't let major housework outstrip your equity. If you're already a WaFd home loan client, be sure to contact
          your loan officer to discuss options.
        </h3>
        <ReturningBorrowerLink loanType="Remodeling Loan" />
      </section>

      <MortgageCalculator {...calculatorData} />

      <CustomLargeCards noBackground imgData={largeCardsImgData} cardsData={largeCardsData} />

      <NewUIWafdBankBenefits />

      <FaqAccordion {...faqAccordionData} />

      <StretchedLinkCards {...stretchedCardsData} />

      <HelpSection {...helpSectionData} />
    </SecondaryLanding>
  );
};

export default HomeImprovementLoans;
